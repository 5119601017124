<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice/wrongbook' }">错题本</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content flex">
        <div class="left">
          <span class="title theme-c"> 章节 </span>
          <div class="text-overflow" v-for="(item, index) in list" :key="item.id" @click="changeTab(index, item)"
            :class="[checkIndex === index ? 'theme-curr' : '', 'theme-h', 'f16']">
            <el-row :gutter="10" class="sec-d">
              <el-col :span="24">
                <span class="sec">{{ item.objectName }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="right flex1">
          <div class="log-l">
            <span class="title"> 错题列表 </span>

            <template v-if="detailList.length>0">
              <div style="margin: 20px 0" v-for="(item, index) in detailList" :key="item.id">
                <div class="que-t flex justify-between">
                  <span class="f16 cont"> {{ index + 1 }}.{{ item.content }} </span>
                  <span class="gray">
                    {{ item.createDt | dataFormat }}
                  </span>
                </div>

                <div class="result gray" style="margin: 8px 0">
                  <template>
                    <span class="f16" style="margin: 0 20px 0 8px">答案：
                      <span class="theme-c">{{ handleAnswer(item.type, item.memberAnswer) || "未填 " }}</span></span>
                    <span class="f16">
                      正确答案：<span style="color: #47bdff">{{ handleAnswer(item.type, item.answer) }}</span></span>
                  </template>
                </div>

                <div class="flex">
                  <div class="det-t f16">试题详解：</div>
                  <div class="gray">{{ item.analysis }}</div>
                </div>
              </div>
            </template>
             
            <NoneImg v-else />

            <div class="pageInfo">
              <el-pagination background @current-change="handleCurrentChange" :current-page.sync="queryInfo.currPage"
                :page-size="queryInfo.pageSize" layout=" prev, pager, next,total, jumper" :total="count">
              </el-pagination>
            </div>

            <div class="flex justify-center">
              <div class="btn" @click="jump">错题重做</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      checkIndex: null,
      // 分页参数
      queryInfo: {
        currPage: 1,
        pageSize: 5,
      },
      count: 0,
      memberCode: "",
      list: [],
      detailList: [],
      currItem: null,
    };
  },
  methods: {
    // 修改当前页
    handleCurrentChange (newCurr) {
      this.queryInfo.currPage = newCurr;
      this._getDetailList();
    },

    jump () {
      this.$router.push({
        path: '/practice/dowrongbook',
        query: {
          objectCode: this.currItem.objectCode,
          pageSize: this.count
        }
      }
      );
    },

    handleAnswer (type, val) {
      switch (type) {
        case "3":
          return val === "1" ? "对" : "错";
        default:
          return val;
      }
    },

    changeTab (index, item) {
      //左侧菜单切换
      this.checkIndex = index;
      this.currItem = item;
      this._getDetailList();
    },

    getLeftlist () {
      // 左侧
      this.$api.wBook
        .getList({
          memberCode: this.memberCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.list = res.data.data;
            if (this.list.length > 0) {
              this.changeTab(0, this.list[0])
            }
          }
        });
    },

    _getDetailList () {
      //详情
      this.$api.wBook
        .getDetailList({
          currPage: this.queryInfo.currPage,
          pageSize: this.queryInfo.pageSize,
          condition: {
            objectCode: this.currItem.objectCode,
            memberCode: this.memberCode,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.detailList = res.data.data.data;
            this.count = res.data.data.count;
          }
        });
    },
  },
  created () {
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.getLeftlist();
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: left;
  margin: 30px 0;
  height: 790px;
  background: #f4f4f4;
  .left {
    background: #f9fbfc;
    width: 250px;
    height: 100%;
    padding: 12px 0;
    overflow-x: hidden;
    .title {
      font-weight: 700;
      font-size: 24px;
      padding: 0 20px;
    }
    .sec-d {
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      .sec {
        font-size: 16px;
        font-weight: 700;
      }
      .des {
        // color: #7f7f7f;
      }
    }

    .gray-bg {
      background: #ccc;
    }
  }
  .right {
    height: 100%;
    background: #fff;
    .log-l {
      height: 460px;
      padding: 12px 22px;
      .title {
        font-weight: 700;
        font-size: 24px;
        color: #7f7f7f;
      }
    }
    .pageInfo {
      text-align: right;
    }
    .btn {
      margin-top: 30px;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      background: #db3b3d;
      width: 260px;
      height: 50px;
      line-height: 50px;
      border-radius: 41px;
      text-align: center;
    }
    .cont {
      line-height: 27px;
      width: 80%;
    }
  }
}
.f16 {
  font-size: 16px;
}
.f13 {
  font-size: 13px;
}
.gray {
  color: #7f7f7f;
}
.det-t {
  width: 85px;
  margin-left: 8px;
}
</style>
